<template>
  <div class="login_container">

    <div class="login_box">
      <!-- 标题 -->
      <div class="login_title">
        MHP运维系统
      </div>
      <!-- 头像去 -->

      <!-- 登陆表单区 -->
      <el-form ref="form" :rules="rules" :model="form" label-width="0px" class="login_form">
        <!-- 用户名 -->
        <el-form-item prop="userName">
          <el-input prefix-icon="el-icon-user" placeholder="请输入账号" v-model="form.userName"></el-input>
        </el-form-item>

        <!-- 密码 -->
        <el-form-item prop="passWord">
          <el-input prefix-icon="el-icon-lock" placeholder="请输入密码" :show-password="true"
            v-model="form.passWord"></el-input>
        </el-form-item>

        <!-- 按钮区 -->

        <el-form-item class="btns">
          <el-button type="primary" @click="submit('form')">登陆</el-button>
          <el-button type="info" @click="reset('form')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div></div>
  </div>
</template>

<script>
import md5 from "js-md5";


export default {
  //dom加载完毕后
  mounted() { },
  created() {
    this.init();
  },
  data() {
    return {
      form: {
        userName: "",
        passWord: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度在 11 个字符",
            trigger: "blur",
          },
        ],
        passWord: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            message: "密码长度不能小于6位",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submit(formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          //MD5
          localStorage.setItem("passWord", this.form.passWord);
          this.form.passWord = md5(this.form.passWord);
          this.$post("/user/login", this.form).then((res) => {
            if (res.success && res.statusCode == 200) {
              localStorage.setItem("token", res.data.token);

              localStorage.removeItem("userInfo")
              localStorage.setItem("userInfo", JSON.stringify(res.data));
              localStorage.setItem("userId", JSON.stringify(res.data.id));
              localStorage.setItem("phone", JSON.stringify(res.data.phone));
          
              //设置token到请求头中
              this.$setToken();
              this.getMenu()
            }
          });
        }
      });
    },
    getMenu() {
      //获取默认皮肤
      this.$get("/user/getUserMenu", {}).then((res) => {
        if (res.success && res.statusCode == 200) {
          let menuList = res.data;
          let arr = menuList.filter(f => f.menuName == '首页');
          if (arr.length > 0) {
            this.$router.push("/home");
          } else {
            try {
              let url = "/welcome";
              this.$router.push(url);
            } catch (error) {
              this.$router.push("/home");
            }

          }

        }
      });
    },
    reset(formName) {
      this.$refs[formName].resetFields();
    },
    init() {
      let phoneStorage = localStorage.getItem("phone")
      let passWord = localStorage.getItem("passWord");
      if (phoneStorage) {
          this.form.userName = phoneStorage.replace("\"", "").replace("\"", ""); 
      }
      if(passWord){
        this.form.passWord = passWord; 
      }
    }
  },

};
</script>

<style lang="less" scoped>
.login_container {
  background-color: #2b4b6b;
  vertical-align: middle;
  height: 100vh;
  width: 100%;
  background-image: url(../assets/bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.login_box {
  width: 450px;
  height: 300px;
  background-color: #EBEEF5;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.avatar_box img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eee;
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

}

.login_title {
  position: absolute;
  font-weight: 800;
  letter-spacing: 10px;
  font-size: 25px;
  top: 20px;
  left: 25%;
  //
  overflow: hidden; //超出部分隐藏
  background-image: -webkit-linear-gradient(left,
      green,
      yellow,
      pink,
      blue,
      black 15%,
      red 25%,
      green 35%,
      blue 50%,
      yellow 60%,
      red 75%,
      pink 85%,
      blue 100%); //括号内可添加多种颜色，多种百分比   线性渐变
  -webkit-text-fill-color: transparent; //颜色填充 透明
  -webkit-background-clip: text; //背景颜色绘制区域
  animation: stream 2s infinite linear; //流动 15秒 循环 直线
  background-size: 200% 100%;
}

@keyframes stream {

  //匀速流动
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -100% 0;
  }
}
</style>